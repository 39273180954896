import { environment } from './../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';
import { icons } from '../../icon/icons';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'MenuLink',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss'
})
export class LinkComponent implements OnInit {

  env = environment.mode;

  @Input() label!:string;
  @Input() link!:string;
  @Input() disabled = false;
  @Input() icon!:keyof typeof icons;

  classDefault = 'hover:bg-gray-500 hover:text-white rounded-md p-2 min-h-10 min-w-10 mx-1 transition-all disabled:opacity-40'

  active = "bg-blue-500 text-white"

  ngOnInit(): void {
   this.disabled = (this.env == 'development') ? false : this.disabled;
  }

}
