import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { RouterModule } from '@angular/router';
import { VersionService } from '../../services/version.service';
import { IconComponent } from '../icon/icon.component';
import { SidebarLinkComponent } from './link/link.component';
import { MePipe } from '../../pipes/me.pipe';
import { BranchPipe } from '../../pipes/branch.pipe';
import { HasRoleDirective } from '../../directives/has-role.directive';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule, SidebarLinkComponent, MePipe, BranchPipe, HasRoleDirective],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  roles!: any;

  menus!: any;

  classLinks = "disabled:opacity-40 flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-3 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

  constructor(
    private sidebarService: SidebarService,
    public versionService: VersionService
  ) {}

  isOpen = true;

  ngOnInit(): void {
    this.initMenus();
  }

  close() {
    this.isOpen = false;
    setTimeout(() => this.sidebarService.emitClose(), 200);
  }

  initMenus() {
    this.menus = {
      admin: { open: false },
    };
  }

  openMenu(menu: string) {
    this.menus[menu].open = !this.menus[menu].open;
  }
}
