import { SidebarService } from './../sidebar.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, output } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';
import { icons } from '../../icon/icons';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'SidebarLink',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
})
export class SidebarLinkComponent {

  env = environment.mode;

  @Input() label!: string;
  @Input() link!: string;
  @Input() disabled = false;
  @Input() icon!: keyof typeof icons;

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  classDefault =
    'disabled:opacity-40 flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-3 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700';

  active = 'bg-blue-400 hover:bg-blue-700';

  ngOnInit(): void {
    this.disabled = (this.env == 'development') ? false : this.disabled;
   }

  closeSidebar(){
    this.clicked.emit();
  }

}
