<nav class="fixed left-0 top-0 flex justify-center w-screen z-10 min-h-16">
  <div class="w-screen md:m-2 md:rounded-lg shadow-lg shadow-gray-200 bg-gray-50 dark:shadow-slate-900 dark:bg-slate-950 flex relative">

    <div class="p-2 flex flex-row items-center justify-between w-full">

      <div class="flex h-auto justify-between md:justify-start w-full me-5" role="group">

        <img src="assets/images/logo/logo_color.svg" class="h-10 p-2 hidden md:block" style="min-width: 50px;">

        <MenuLink icon="menu" (click)="openSidebar()"/>

        <MenuLink icon="calendar" label="Reservas" link="/reservas"/>

        <MenuLink icon="parkedCar" label="Entrada" link="/atendimento"/>

        <MenuLink icon="checklist" label="Checklists" link="/checklists"/>

        <MenuLink icon="carPending" label="Pendentes" link="/patio/pendentes"/>

        <MenuLink icon="garage" label="Pátio" link="/patio"/>

        <MenuLink icon="cash" label="Caixa" link="/caixa"/>

        <MenuLink icon="conference" label="Conferências" link="/conferencias" class="hidden md:block"/>

        <MenuLink icon="reports" label="Relatórios" link="/relatorios" [disabled]="true" class="hidden md:block"/>

      </div>

    </div>

    <div class="hidden md:flex items-center absolute right-3 top-8 text-xs opacity-40">
      V{{versionService.get}}
    </div>

    <div class="hidden md:flex md:items-center w-1/2 h-full p-2">
      <ng-content></ng-content>
    </div>

  </div>
</nav>

<!-- to push page down -->
<div class="block mb-16"></div>
