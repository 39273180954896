<div
  class="z-50 fixed bottom-0 left-0 w-11/12 md:w-72 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white dark:bg-gray-800 transform -translate-x-full"
  [class.translate-x-0]="isOpen" [class.-translate-x-full]="!isOpen">

  <button (click)="close()" type="button"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
    <Icon src="close" />
    <span class="sr-only">Close menu</span>
  </button>

  <div class="py-4 overflow-y-auto">

    <div class="flex justify-start items-center flex-col mb-5">

      <div class="flex items-center justify-between w-full">

        <div class="flex flex-col justify-start w-1/2 my-5">
          <div class="text-lg truncate ...  font-bold"> {{ 'name' | me}} </div>
          <small class="truncate ... flex items-center flex-row">
            <div class="w-2 h-3 block rounded-md me-2" style.background="{{'color_cod' | branch}}"></div> {{ 'name' | branch}}
          </small>
        </div>

        <div class="min-w-10  w-1/2 ">
          <button disabled class="disabled:opacity-55 hover:bg-gray-200 p-2 rounded-md text-xs flex justify-center items-center me-5"> <Icon src="myData" class="ms-2"/> Meus dados</button>
        </div>

      </div>

      <div class="flex flex-col w-full justify-center">

        <button (click)="close()" [routerLink]="['/auth/logout']" [class]="classLinks">
          <Icon src="doorExit" /> Sair
        </button>

        <div class="space-y-2 font-medium">

          <hr class="mb-3" />

          <SidebarLink (clicked)="close()" icon="calendar" label="Reservas" link="/reservas" />
          <SidebarLink (clicked)="close()" icon="parkedCar" label="Entrada" link="/atendimento" />
          <SidebarLink (clicked)="close()" icon="checklist" label="Checklists" link="/checklists" />
          <SidebarLink (clicked)="close()" icon="carPending" label="Pendentes" link="/patio/pendentes"/>
          <SidebarLink (clicked)="close()" icon="garage" label="Pátio" link="/patio"/>
          <SidebarLink (clicked)="close()" icon="cash" label="Caixa" link="/caixa"/>
          <SidebarLink (clicked)="close()" icon="conference" label="Conferências" link="/conferencias" />
          <SidebarLink (clicked)="close()" icon="reports" label="Relatórios" link="/relatorios" [disabled]="true"/>

          <span *HasRole="['ADMIN']">
            <button  type="button" (click)="openMenu('admin')"
              class="disabled:opacity-40 flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
              <Icon src="admin" class="me-3" />
              <span class="flex-1 text-left rtl:text-right whitespace-nowrap">Administração</span>
              <Icon [src]="!menus.admin.open ? 'arrowDown' : 'arrowUp'" />
            </button>
            <ul class="ms-3 py-2 space-y-2" [ngClass]="{'hidden':!menus.admin.open}">
              <li>
                <SidebarLink (clicked)="close()" icon="users" label="Usuários" link="/administracao/usuarios" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="vehicleManager" label="Veículos" link="/administracao/veiculos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="vehicleModel" label="Modelos" link="/administracao/modelos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="agreement" label="Contratos" link="/administracao/contratos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="customer" label="Clientes" link="/administracao/clientes" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="buildings" label="Unidades" link="/administracao/unidades" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="moneys" label="Despesas e Receitas" link="/administracao/despesas-receitas" />
              </li>
              <!-- <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="cash" label="Caixas" link="/administracao/caixas" />
              </li> -->
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="carTag" label="Mensalistas" link="/administracao/mensalistas" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="car" label="Entradas" link="/administracao/atendimentos" />
              </li>
            </ul>
          </span>

          <hr class="mb-3" />

          <SidebarLink (clicked)="close()" [disabled]="true" icon="school" label="Tutoriais" link="/tutoriais" />

          <SidebarLink (clicked)="close()" [disabled]="true" icon="code" label="Litwo" link="/litwo" />

          <div class="opacity-50 dark:opacity-25 mx-3 border-t border-gray-500 text-sm">
            Versão: {{versionService.get}}
          </div>


        </div>


      </div>

    </div>


  </div>

</div>

<div (click)="close()" class="absolute left-0 top-0 w-screen h-screen bg-slate-800 bg-opacity-40 z-40"></div>
