import { SidebarService } from './../sidebar/sidebar.service';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { LinkComponent } from './link/link.component';
import { VersionService } from '../../services/version.service';

@Component({
  selector: 'Menu',
  standalone: true,
  imports: [CommonModule, IconComponent, LinkComponent],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  constructor(
    private sidebarService: SidebarService,
    public versionService: VersionService
  ) {}

  openSidebar() {
    this.sidebarService.emitOpen();
  }
}
